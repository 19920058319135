"use client";

import { ApgUser } from "@/domain/account.interface";
import { FC, useState } from "react";
import { useRouter } from 'next-nprogress-bar';
import Dropdown from "@apg.gg/core/lib/Dropdown";
import Button from "@apg.gg/core/lib/Button";
import classNames from "classnames";
import CelebrationIcon from '@apg.gg/icons/lib/CelebrationIcon';
import MegaphoneIcon from '@apg.gg/icons/lib/MegaphoneIcon';
import PersonIcon from '@apg.gg/icons/lib/PersonIcon';
import ReportIcon from '@apg.gg/icons/lib/ReportIcon';
import MoreIcon from '@apg.gg/icons/lib/MoreIcon';
import { useTranslations } from "next-intl";
import DropdownItem from "@apg.gg/core/lib/DropdownItem";

interface ProfileMoreMenuProps {
  user: ApgUser;
  onReportProfile?: () => void;
}

const ProfileMoreMenu: FC<ProfileMoreMenuProps> = ({ user, onReportProfile }) => {
  const t = useTranslations('Profile');
  const router = useRouter();
  const [profileMenuOpen, setProfileMenuOpen] = useState<boolean>(false);

  const items = [
    {
      key: '1',
      label: t('more-menu.see-fans'),
      icon: <CelebrationIcon />,
      onClick: () => router.push(`${user.username}/fans`),
      className: "text-sm hover:text-yellow"
    },
    {
      key: '2',
      label: t('more-menu.see-vouches'),
      icon: <MegaphoneIcon />,
      onClick: () => router.push(`${user.username}/vouches`),
      className: "text-sm hover:text-limegreen"
    },
    {
      key: '3',
      label: t('more-menu.see-followers'),
      icon: <PersonIcon />,
      onClick: () => router.push(`${user.username}/followers`),
      className: "text-sm hover:text-aqua-100"
    },
    {
      key: '4',
      label: t('more-menu.see-following'),
      icon: <PersonIcon />,
      onClick: () => router.push(`${user.username}/followings`),
      className: "text-sm hover:text-blue-200"
    },
    {
      key: '5',
      label: t('more-menu.report-profile'),
      icon: <ReportIcon />,
      onClick: onReportProfile,
      className: "text-sm hover:text-orange-400"
    }
  ]
  
  return (
    <Dropdown 
      placement='bottomRight'
      trigger={['click']}
      visible={profileMenuOpen}
      onVisibleChange={(visible) => setProfileMenuOpen(visible)}
      overlayClassName='bg-black shadow-md rounded p-2 z-50'
      content={
        <div className="flex flex-col gap-1 w-40">
          {items.map((item, index) => (
            <DropdownItem 
              key={`${item.label}-${index}`} 
              item={item} 
              prefixCls='dropdown' 
              handleItemClick={() => {
                setProfileMenuOpen(false)
                item.onClick?.()
              }} 
            />
          ))}
        </div>
      }
    >
      <Button
        onClick={() => setProfileMenuOpen(!profileMenuOpen)}
        type="outline"
        iconSize="xl"
        icon={<MoreIcon />}
        className={classNames(
          "!w-8 !h-8"
        )}
      ></Button>
    </Dropdown>
  )
}

export default ProfileMoreMenu