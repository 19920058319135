import { apgGraphQL } from "../apgApi"
import { RequestError } from "../general.endpoints";
import { SelectOption } from '@apg.gg/core/lib/Select';
import { operationMessages } from "./queries";
import { ChatItem, ChatMessage } from "@/domain/messages/interfaces";
import { InfinitePaginationResponse } from "@/domain/general.interface";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token?: string;
  data: T;
}

export type LastMessage = {
  id: number,
  chatId: string,
  authorId: number,
  chat: {
    chatName: string
    status: string
    type: string
  }
}

const getUserChats = async ({
  userId,
  status,
  limit = 10,
  page = 1,
  token
}: any): Promise<InfinitePaginationResponse<ChatItem>> => {
  const offset = (page - 1) * limit;

  const { data, errors } = await apgGraphQL(
    operationMessages,
    'GetUserChats',
    {
      "userId": userId,
      "limit": limit,
      "offset": offset,
      "status": status
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: data.chats,
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
  }
}

const getChatMessages = async ({
  chatId,
  status,
  limit = 15,
  page = 1,
  token
}: any): Promise<InfinitePaginationResponse<ChatMessage[]> & { chat: ChatItem }> => {
  const offset = (page - 1) * limit;

  const { data, errors } = await apgGraphQL(
    operationMessages,
    'GetChatMessages',
    {
      "chatId": chatId,
      "limit": limit,
      "offset": offset,
      "status": status
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    items: groupMessages(data.messages),
    nextPage: page < totalPages ? page + 1 : null,
    previousPage: page > 0 ? page - 1 : null,
    totalPages: totalPages,
    total: totalResults,
    currentPage: page,
    chat: data.chat
  }
}

const getLastMessageId = async ({
  chatId,
  token,
}: any): Promise<LastMessage> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'GetLastMessageId',
    {
      "chatId": chatId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.lastMessageId[0];
}

const getChatTotalPages = async ({
  chatId,
  limit = 15,
  token,
}: any): Promise<{
  totalPages: number,
  totalResults: number
}> => {

  const { data, errors } = await apgGraphQL(
    operationMessages,
    'GetChatTotalPages',
    {
      "chatId": chatId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  const totalResults = data.pagination.data.total;
  const resultsPerPage = limit;
  const totalPages = Math.ceil(totalResults / resultsPerPage);

  return {
    totalPages: totalPages,
    totalResults: totalResults,
  }
}

const sendMessage = async ({
  chatId,
  authorId,
  message,
  token,
}: { 
  chatId: string, 
  authorId: number, 
  message: string, 
  token: string 
}): Promise<string> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'SendMessage',
    {
      "object": {
        "chatId": chatId,
        "authorId": authorId,
        "message": message
      }
    },
    token
  )
  
  if (errors) {
    throw getError(errors[0] as RequestError)
  }
  
  const { errors: errorsChat } = await apgGraphQL(
    operationMessages,
    'UpdateChatDate',
    {
      "id": chatId
    },
    token
  )
    
  if (errorsChat) {
    throw getError(errorsChat[0] as RequestError)
  }
    
  return data.insertMessage.id;
}

const sendPayedMessage = async ({
  chatId,
  authorId,
  message,
  token,
  redeemId,
  cost
}: { 
  chatId: string, 
  authorId: number, 
  message: string, 
  token: string,
  redeemId: number,
  cost: number
}): Promise<boolean> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'SendPayedMessage',
    {
      "payload": {
        "chatId": chatId,
        "authorId": authorId,
        "message": message,
        "redeemId": redeemId,
        "cost": cost
      }
    },
    token
  )
  
  if (errors) {
    throw getError(errors[0] as RequestError)
  }
  
  const { errors: errorsChat } = await apgGraphQL(
    operationMessages,
    'UpdateChatDate',
    {
      "id": chatId
    },
    token
  )
    
  if (errorsChat) {
    throw getError(errorsChat[0] as RequestError)
  }
    
  return data.insertPayedMessage.success;
}

const subscribeLastMessage = async (): Promise<SelectOption[]> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'getChatMessages'
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data;
}

const checkChatExist = async ({
  userId,
  profileId,
  token,
}: { 
  userId: number,
  profileId: number, 
  token: string
}): Promise<{
  chatId: string,
  success: boolean,
  message: string,
  code: number
}> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'CheckChatExist',
    {
      "userId": userId,
      "profileId": profileId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.checkChatExist;
}

const updatedChat = async ({
  chatId,
  payload,
  users,
  token,
}: { 
  chatId: string,
  payload: Record<string, any>,
  users: Record<string, any>,
  token: string
}): Promise<string> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'UpdatedChat',
    {
      "chatId": chatId,
      "payload": payload,
      "users": users
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updatedChat.status;
}

const insertFirstMessage = async ({
  payload,
  token,
}: {
  payload: Record<string, any>,
  token: string
}): Promise<{
  chatId?: string,
  code?: number,
  message?: string,
  success: boolean,
}> => {
  const { data, errors } = await apgGraphQL(
    operationMessages,
    'InsertFirstMessage',
    {
      "payload": payload,
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertFirstMessage;
}

const groupMessages = (messages: ChatMessage[]): ChatMessage[][] => {
  const messageGroups: ChatMessage[][] = [];
  let currentGroup: ChatMessage[] = [];

  // Paso 4
  for (const message of messages) {
    if (currentGroup.length === 0) {
      // Paso 5
      currentGroup.push(message);
    } else {
      const lastMessage = currentGroup[currentGroup.length - 1];
      const timeDifference = Math.abs(new Date(message.createdAt).getTime() - new Date(lastMessage.createdAt).getTime()) / 60000; // en minutos

      if (lastMessage.authorId === message.authorId && timeDifference <= 5) {
        // Paso 6
        currentGroup.push(message);
      } else {
        // Paso 7
        messageGroups.push(currentGroup.reverse());
        currentGroup = [message];
      }
    }
  }

  // Paso 8
  messageGroups.push(currentGroup.reverse());

  // Paso 9
  return messageGroups;
};

export const ChatEndpoints = {
  getUserChats,
  getChatMessages,
  getChatTotalPages,
  getLastMessageId,
  sendMessage,
  sendPayedMessage,
  checkChatExist,
  subscribeLastMessage,
  updatedChat,
  insertFirstMessage
}
