import { UserSocials } from "@/domain/account.interface";
import generateSocialMediaLink from "@/lib/utils/generateSocialMediaLink";
import getComponentByName from "@/lib/utils/mapping";
import Tooltip from "@apg.gg/core/lib/Tooltip";
import renderIcon from "@apg.gg/core/lib/renderIcon";
import _ from "lodash";
import Link from "next/link";
import { FC, useState } from "react";

interface SocialIconProps {
  social: UserSocials,
  className?: string,
}

const SocialIcon: FC<SocialIconProps> = ({ social, className = "text-xl text-white" }) => {
  const { socialAccount, link } = social;
  const socialName = socialAccount?.name;
  const [tooltipText, setTooltipText] = useState(socialAccount?.name);

  const handleCopyToClipboard = (value: string) => {
    // Copiar el valor al portapapeles
    navigator.clipboard.writeText(value);

    // Cambiar el texto del Tooltip a "Copiado"
    setTooltipText('Copiado');

    // Restaurar el texto del Tooltip después de un tiempo
    setTimeout(() => {
      setTooltipText(socialAccount?.name);
    }, 1000); // Cambia esto según cuánto tiempo quieres que se muestre "Copiado"
  };

  // Verificar si se debe mostrar el Link o el ícono con la funcionalidad de copiar
  if (socialName === 'discord' || socialName === 'epic' || socialName === 'riot') {
    return (
      <div
        key={`${socialAccount?.id}-${socialName}`}
        className="flex gap-2 items-center"
        onClick={() => handleCopyToClipboard(link)}
        style={{ cursor: 'pointer' }}
      >
        <Tooltip text={tooltipText} placement="top">
          {renderIcon(getComponentByName(socialName, className), '')}
        </Tooltip>
      </div>
    );
  } else {
    return (
      <Link href={generateSocialMediaLink(socialName.toLocaleLowerCase(), link)} target="_blank" key={`${socialAccount?.id}-${socialName}`} className="flex gap-2 items-center">
        <Tooltip text={tooltipText} placement="top">
          {renderIcon(getComponentByName(_.camelCase(socialName === 'Website' ? 'language' : socialName), className), '')}
        </Tooltip>
      </Link>
    );
  }
}

export default SocialIcon;