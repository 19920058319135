import React, { ReactNode, useState } from 'react';
import { ApgUser, UserProfileType, UserSocials } from '@/domain/account.interface';
import Button from '@apg.gg/core/lib/Button';
import Drawer from '@apg.gg/core/lib/Drawer';
import Tag from '@apg.gg/core/lib/Tag';
import Tooltip from '@apg.gg/core/lib/Tooltip';
import Upload from '@apg.gg/core/lib/Upload';
import { differenceInYears } from 'date-fns';
import Image from 'next/image';
import { toast } from 'react-toastify';
import EditIcon from '@apg.gg/icons/lib/EditIcon';
import PlusIcon from '@apg.gg/icons/lib/PlusIcon';
import VerifiedColorIcon from '@apg.gg/icons/lib/VerifiedColorIcon';
import BetaColorIcon from '@apg.gg/icons/lib/BetaColorIcon';
import SocialIcon from '../SocialIcon';
import { useRouter } from 'next-nprogress-bar';
import classNames from 'classnames';
import Link from 'next/link';
import ProfileMoreMenu from '../ui/ProfileMoreMenu';
import DownloadIcon from '@apg.gg/icons/lib/DownloadIcon';
import MegaphoneIcon from '@apg.gg/icons/lib/MegaphoneIcon';
import { useTranslations } from 'next-intl';
import useAuthStore from '@/store/auth.store';
import { GeneralEndpoints, RequestError } from '@/graph/general.endpoints';
import { ERROR_CODES } from '@/lib/utils/constants';
import { useUpdateUserAccount } from '@/hooks/useUser/useUser';
import MailIcon from '@apg.gg/icons/lib/MailIcon';
import { ChatEndpoints } from '@/graph/messages/endpoints';
import VisibilityIcon from '@apg.gg/icons/lib/VisibilityIcon';
import { cn } from '@/lib/utils';
import useWindowSize from '@apg.gg/core/lib/useWindowSize';

interface ProfileHeaderProps {
  user: ApgUser,
  showFollowButton?: boolean;
  onFollow?: () => void;
  onUnfollow?: () => void;
  onFan?: () => void;
  onUnfan?: () => void;
  onVouch?: () => void;
  onClickSort?: () => void;
  isEditing?: boolean;
  isFollowing?: boolean;
  isMyIdol?: boolean;
  isVouched?: boolean;
  isVerified?: boolean;
}

const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  user,
  showFollowButton,
  onFollow,
  onUnfollow,
  onFan,
  onUnfan,
  onVouch,
  onClickSort,
  isEditing = false,
  isFollowing,
  isMyIdol,
  isVouched,
  isVerified
}) => {
  const t = useTranslations();
  const { isXs } = useWindowSize();
  const router = useRouter();
  const { userId, authToken, isLogged } = useAuthStore();
  const [isUploadingBanner, setIsUploadingBanner] = useState(false);
  const [isUploadingAvatar, setIsUploadingAvatar] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const [profileBanner, setProfileBanner] = useState<string>(user?.bannerImage || "");
  const [profileAvatar, setProfileAvatar] = useState<string>(user?.profileImage || "");
  const updateUserAccount = useUpdateUserAccount(userId as number, authToken as string);

  const [showAllProfiles, setShowAllProfiles] = useState(false);

  const sucessfullyUpdateImage = (type: string) => {
    toast.success(`${t('Profile.profile-image-updated-successfully', { type: type === 'apg-storage-banners' ? 'banner' : 'avatar' })}`);
  }

  const profilesToShow = user.profileTypes && user.profileTypes.length <= 5 ? user.profileTypes : user.profileTypes?.slice(0, 5);

  const onHandleUpload = async (base64: string, type: string): Promise<string> => {
    type === 'apg-storage-banners' ? setIsUploadingBanner(true) : setIsUploadingAvatar(true);

    return await GeneralEndpoints.insertFile({
      file: base64,
      userId: userId as number,
      name: user.username,
      type,
      token: authToken
    }).then(async (response) => {
      sucessfullyUpdateImage(type);
      type === 'apg-storage-banners' ? setIsUploadingBanner(false) : setIsUploadingAvatar(false);

      await updateUserAccount.mutateAsync({
        profileImage: type === 'apg-storage-banners' ? profileAvatar : response.imageUrl,
        bannerImage: type === 'apg-storage-banners' ? response.imageUrl : profileBanner,
        profileImageOg: type === 'apg-storage-banners' ? user.profileImageOg : response.imageOg,
        bannerImageOg: type === 'apg-storage-banners' ? response.imageOg : user.bannerImageOg,
      });

      return response.imageUrl;
    }).catch(error => {
      const errorMessage = ERROR_CODES(t)[(error as RequestError).message as string] || 'Error uploading image';
      toast.error(errorMessage);
      type === 'apg-storage-banners' ? setIsUploadingBanner(false) : setIsUploadingAvatar(false);

      return '';
    });
  }

  const renderSection = (): ReactNode => {
    return (
      <>
        {isEditing ? (
          <div className="flex gap-2 h-8">
            <Button
              onClick={() => onClickSort?.()}
              type="outline"
            >
              {t('Profile.button-manage-sections')}
            </Button>
          </div>
        ) : (
          <div className="flex gap-2 h-8 ">
            <ProfileMoreMenu user={user} />
            {user.cvLink ? (
              <Tooltip text={t('Profile.button-download-cv')} placement="bottom">
                <Button
                  onClick={() => {
                    const url = user.cvLink;
                    window.open(url, '_blank');
                  }}
                  type="outline"
                  icon={<DownloadIcon />}
                  className={classNames(
                    "!w-8 !h-8"
                  )}
                ></Button>
              </Tooltip>
            ) : null}
            {isFollowing ? (
              <>
                <Tooltip text={t('messages.label-message')} placement="bottom">
                  <Button
                    onClick={async () => {
                      const checkChatExist = await ChatEndpoints.checkChatExist({ userId: userId as number, profileId: user.id, token: authToken });

                      if (checkChatExist.success) {
                        if (checkChatExist.chatId) {
                          router.push(`/messages/${checkChatExist.chatId}`);
                        } else {
                          router.push(`/messages/new/${user.id}`);
                        }
                      } else {
                        toast.error(checkChatExist.message);
                      }
                    }}
                    type="outline"
                    iconSize="2xl"
                    className={classNames(
                      "!w-8 !h-8 !text-blue-400 !border-blue-400 hover:!bg-blue-400-100/20",
                    )}
                    icon={<MailIcon />}
                  ></Button>
                </Tooltip>
                <Tooltip text={isVouched ? t('Profile.tooltip-label-vouched') : t('Profile.tooltip-label-vouch')} placement="bottom">
                  <Button
                    onClick={() => {
                      if (!isVouched) {
                        onVouch?.();
                      }
                    }}
                    type="outline"
                    iconSize="2xl"
                    className={classNames(
                      "!w-8 !h-8",
                      {
                        "!text-limegreen !border-limegreen hover:!bg-limegreen-100/20": !isVouched,
                        "!bg-limegreen-100/20 !text-limegreen !border-limegreen !cursor-default": isVouched
                      }
                    )}
                    icon={<MegaphoneIcon />}
                  ></Button>
                </Tooltip>
              </>
            ) : null}
            {showFollowButton ? (
              <div
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <Button
                  onClick={async () => {
                    if (isFollowing) {
                      onUnfollow?.();
                    } else {
                      onFollow?.();
                    }
                  }}
                  type="outline"
                  className={classNames(
                    "!h-8",
                    {
                      "hover:bg-red/20 hover:text-red hover:border-red w-36": isFollowing
                    }
                  )}
                >
                  {isFollowing ? (isHovered ? t('Profile.button-unfollow') : t('Profile.button-following')) : t('Profile.button-follow')}
                </Button>
              </div>
            ) : null}
          </div>
        )}
      </>
    )
  }

  const renderSocialSection = (): ReactNode => {
    return (
      <>
        {user.socials?.map((social: UserSocials) => (
          <SocialIcon
            key={`${user.username}-social-${social.socialAccount?.name}`}
            social={social}
          />
        ))}
        {isEditing ? (
          <Button
            onClick={() => router.push('/account/socials')}
            type="ghost"
            fontSize="xs"
            className="h-6 !pl-2 bg-blue-100/10 text-aqua-100 border-aqua-100"
            icon={<PlusIcon />}
          >
            {user.socials?.length === 0 ? t("Common.profile-header.add-social-networks") : t("Common.profile-header.add")}
          </Button>
        ) : null}
      </>
    )
  }

  return (
    <>
      <section id="header" className="row bg-black-800">
        <div className="container">
          <div className="flex flex-col rounded-2xl shadow">
            <div className="flex bg-blue overflow-hidden w-full rounded-t-2xl">
              {isEditing ? (
                <Upload
                  useBase64={true}
                  onBase64={async (base64) => {
                    const imageUrl = await onHandleUpload(base64, 'apg-storage-banners');
                    setProfileBanner(imageUrl);
                  }}
                  shape={"bannerimage" as "banner" | "square" | "circle" | undefined}
                  width={1076}
                  height={isXs ? 96 : 160}
                  authToken={authToken}
                  image={profileBanner}
                  editMode={isLogged}
                  iconAdd={<PlusIcon className="text-white text-lg" />}
                  iconEdit={<EditIcon className="text-white text-lg" />}
                  title={t('Common.label-upload-image')}
                  headline={`${t('Common.label-max-size', { size: '2MB' })}. ${t('Common.label-recommended-size', { width: '1076', height: '160' })}`}
                  editText={t('Common.label-upload-image')}
                  isLoading={isUploadingBanner}
                />
              ) : (
                <div className="flex flex-col w-full max-w-screen-xl overflow-hidden items-center justify-center bg-black">
                  <div className="relative group w-full max-w-screen-xl h-24 md:h-40 aspect-auto">
                    <Image
                      src={user?.bannerImage || '/assets/resources/home-bg-main@2x.png'}
                      alt={user?.name}
                      width={1076}
                      height={160}
                      className="w-full object-cover object-center h-24 md:h-40 aspect-auto"
                    />
                  </div>
                </div>
              )}
            </div>
            <div className={cn(
              "flex gap-2 bg-black-900",
              "flex-col h-auto w-full px-4 py-8 items-center",
              "lg:flex-row lg:h-48 lg:p-8 lg:items-start lg:justify-center"
            )}>
              <div className={cn(
                "flex flex-col w-full sm:w-52",
                "items-center justify-center"
              )}>
                <div className="-mt-16 z-50">
                  <div className="flex border-4 border-black-800 rounded-full w-fit overflow-hidden">
                    {isEditing ? (
                      <Upload
                        useBase64={true}
                        onBase64={async (base64) => {
                          const imageUrl = await onHandleUpload(base64, 'apg-storage-profiles');
                          setProfileAvatar(imageUrl);
                        }}
                        shape="circle"
                        height={isXs ? 112 : 170}
                        width={isXs ? 112 : 170}
                        authToken={authToken}
                        image={profileAvatar}
                        editMode={isLogged}
                        iconAdd={<PlusIcon className="text-white text-lg" />}
                        iconEdit={<EditIcon className="text-white text-lg" />}
                        title={!isXs ? t('Common.label-upload-image') : ""}
                        headline={!isXs ? `${t('Common.label-max-size', { size: '2MB' })}.` : ""}
                        editText={t('Common.label-upload-image')}
                        isLoading={isUploadingAvatar}
                      />
                    ) : (
                      <div className="bg-black">
                        <Image
                          src={user?.profileImage || `${process.env.NEXT_PUBLIC_DEFAULT_PROFILE_PIC}`}
                          alt={user?.name}
                          width={250}
                          height={250}
                          className="w-28 sm:w-[170px] h-28 sm:h-[170px] object-cover"
                        />
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="flex flex-col flex-1 gap-3">
                <div className={cn(
                  "flex gap-2 items-center",
                  "flex-col",
                  "lg:flex-row lg:items-start"
                )}>
                  <h1 className="text-white text-title-lg uppercase">{user.name}</h1>
                  <div className="flex gap-2">
                    <span className="text-black-600 text-body-lg">@{user.username}</span>
                    {user.isVerified ? (
                      <Tooltip placement="top" text={t('Profile.this-account-is-verified')}>
                        <VerifiedColorIcon className="text-lg flex cursor-pointer" />
                      </Tooltip>
                    ) : null}
                    {user.isBetaUser ? (
                      <Tooltip placement="top" text={t('Profile.this-account-is-beta-tester')}>
                        <BetaColorIcon className="text-lg flex cursor-pointer" />
                      </Tooltip>
                    ) : null}
                  </div>
                </div>

                <div className="flex lg:hidden justify-center">
                  {renderSection()}
                </div>

                {/* Personal data */}
                <div className={cn(
                  "flex gap-4 items-center",
                  "justify-center",
                  "lg:justify-start"
                )}>
                  {user.birthday && user.showBirthday ? (
                    <div className="flex gap-2 items-center">
                      <span className="text-white text-body-lg">{t('Profile.years-label')}</span>
                      <span className="text-black-600 text-body-lg">{differenceInYears(new Date(), new Date(user.birthday))} {t('Profile.years-old')}</span>
                    </div>
                  ) : null}
                  <div className="flex gap-2 items-center">
                    <span className={`fi fi-${user.country?.iso.toLocaleLowerCase()}`}></span>
                    <span className="text-white text-body-lg">{user.country?.niceName}</span>
                  </div>
                </div>

                {/* Followers & Following */}
                <div className="flex gap-4 items-center mt-auto justify-center lg:justify-start">
                  <Link href={`${user.username}/followers`} className="flex gap-1 items-center">
                    <span className="text-white text-label-lg">{user.followers.data.total}</span>
                    <span className="text-black-600 text-label-lg">{t('Profile.followers-label')}</span>
                  </Link>
                  <Link href={`${user.username}/followings`} className="flex gap-1 items-center">
                    <span className="text-white text-label-lg">{user.followings.data.total}</span>
                    <span className="text-black-600 text-label-lg">{t('Profile.following-label')}</span>
                  </Link>
                </div>
              </div>

              <div className="hidden lg:flex">
                {renderSection()}
              </div>

              <div className="flex lg:hidden gap-2 mt-4">
                {renderSocialSection()}
              </div>
            </div>
            <div className="flex bg-black h-auto lg:h-11 w-full items-center justify-center lg:justify-between px-6 py-2.5 rounded-b-2xl">
              <div className="flex gap-2">
                <div className="flex flex-wrap gap-2 w-full content-center justify-center">
                  {profilesToShow?.map((type: UserProfileType) => {
                    const { profileType } = type;
                    const profile = profileType?.name.replace(' ', '') as string;
                    return (
                      <div key={`${profileType?.id}-${profile}`} className="flex gap-2 items-center">
                        <Tag
                          type={profileType?.color}
                          icon={<Image src={`/assets/icons/${profileType?.icon}/small.png`} alt={profileType?.name || ''} width={16} height={16} />}
                        >
                          {profileType?.name}
                        </Tag>
                      </div>
                    )
                  })}
                  {user.profileTypes && user.profileTypes.length > 5 ? (
                    <Button
                      onClick={() => setShowAllProfiles(true)}
                      type="outline"
                      fontSize="xs"
                      className="h-6 !pl-2"
                      icon={<VisibilityIcon />}
                    >
                      {t('Common.profile-header.view-all')}
                    </Button>
                  ) : null}
                  {isEditing ? (
                    <Button
                      onClick={() => router.push('/account/profile-types')}
                      type="ghost"
                      fontSize="xs"
                      className="h-6 !pl-2 bg-blue-100/10 text-aqua-100 border-aqua-100"
                      icon={<PlusIcon />}
                    >
                      {user.profileTypes?.length === 0 ? t("Common.profile-header.add-profile-types") : t("Common.profile-header.add")}
                    </Button>
                  ) : null}
                </div>
              </div>
              <div className="hidden lg:flex gap-2">
                {renderSocialSection()}
              </div>
            </div>
          </div>
        </div>
      </section>

      {showAllProfiles && (
        <Drawer
          header={t('Profile.modal-profiles-title', { username: user.username })}
          onClose={() => setShowAllProfiles(false)}
          isOpen={showAllProfiles}
          disableClickOutsideToClose={false}
          content={
            <div className="flex flex-wrap gap-2">
              {user.profileTypes?.map((type: UserProfileType) => {
                const { profileType } = type;
                const profile = profileType?.name.replace(' ', '') as string;
                return (
                  <div key={`${profileType?.id}-modal-${profile}`} className="flex gap-2 items-center">
                    <Tag
                      type={profileType?.color}
                      icon={<Image src={`/assets/icons/${profileType?.icon}/small.png`} alt={profileType?.name || ''} width={16} height={16} />}
                    >
                      {profileType?.name}
                    </Tag>
                  </div>
                )
              })}
            </div>
          }
        />
      )}
    </>
  );
};

export default ProfileHeader;
