export const operationMessages = `
  query GetUserChats($offset: Int = 0, $limit: Int = 10, $userId: Int!, $status: String_comparison_exp = {}) {
    chats: communication_chats(limit: $limit, offset: $offset, where: {chatUsers: {userId: {_eq: $userId}, type: {_in: ["member", "initiator", "admin"]}, chat: {chatUsers: {userId: {_neq: $userId}, _or: [{type: {_eq: "member"}}, {type: {_eq: "initiator"}, chat: {messages_aggregate: {count: {predicate: {_gte: 1}}}}}]}}}, status: $status}, order_by: {updatedAt: desc}) {
      chatUsers(where: {userId: {_neq: $userId}}) {
        type
        user {
          username
          profileImage
          name
        }
      }
      status
      type
      chatName
      id
      createdAt
      updatedAt
      lastMessage: messages_aggregate(order_by: {id: desc}, limit: 1) {
        nodes {
          message
          id
          message
          updatedAt
          createdAt
          seen
          authorId
        }
      }
    }
    pagination: communication_chats_aggregate(where: {chatUsers: {userId: {_eq: $userId}, type: {_in: ["member", "initiator", "admin"]}, chat: {chatUsers: {type: {_eq: "member"}, userId: {_neq: $userId}}}}}) {
      data: aggregate {
        total: count(columns: id)
      }
    }
  }

  query GetChatTotalPages($chatId: uuid = "") {
    pagination: communication_messages_aggregate(where: {chatId: {_eq: $chatId}}) {
      data: aggregate {
        total: count(columns: id)
      }
    }
  }  

  query GetChatMessages($offset: Int = 0, $limit: Int = 30, $chatId: uuid = "") {
    chat: communication_chats_by_pk(id: $chatId) {
      id
      status
      type
      updatedAt
      chatName
      chatUsers {
        userId
        type
      }
    }
    messages: communication_messages(limit: $limit, offset: $offset, where: {chatId: {_eq: $chatId}}, order_by: {id: desc, createdAt: desc}) {
      message
      chatId
      authorId
      id
      author {
        id
        profileImage
        username
        name
      }
      updatedAt
      createdAt
    }
    pagination: communication_messages_aggregate(where: {chatId: {_eq: $chatId}}) {
      data: aggregate {
        total: count(columns: id)
      }
    }
  }  

  query GetLastMessageId($chatId: uuid = "") {
    lastMessageId: communication_messages(order_by: {id: desc}, where: {chatId: {_eq: $chatId}}, limit: 1) {
      authorId
      chatId
      chat {
        chatName
        status
        type
      }
      id
    }
  }

  subscription GetLastChatMessages($userId: Int!, $lastMessageId: Int!) {
    stream: communication_messages_stream(batch_size: 1, cursor: {initial_value: {id: $lastMessageId}}, where: {recipients: {userId: {_eq: $userId}}}) {
      authorId
      message
      id
      recipients {
        userId
      }
      chatId
      author {
        id
        name
        profileImage
        username
      }
      createdAt
      updatedAt
    }
  }

  mutation SendMessage($object: communication_messages_insert_input = {}) {
    insertMessage: insert_communication_messages_one(object: $object) {
      id
    }
  }

  mutation SendPayedMessage($payload: InsertPayedMessageInput!) {
    insertPayedMessage(payload: $payload) {
      success
    }
  }


  mutation UpdateChatDate($id: uuid!) {
    update_communication_chats_by_pk(pk_columns: {id: $id}, _set: {updatedAt: now}) {
      id
    }
  }

  mutation CheckChatExist($profileId: Int!, $userId: Int!) {
    checkChatExist(payload: {profileId: $profileId, userId: $userId}) {
      chatId
      success
    }
  }

  mutation UpdateMessagesToSeen($chatId: uuid!, $authorId: Int!) {
    updateMessagesToSeen: update_communication_messages(where: {chatId: {_eq: $chatId}, seen: {_eq: false}, authorId: {_neq: $authorId}}, _set: {seen: true}) {
      affected_rows
      returning {
        id
      }
    }
    update_communication_chats_by_pk(pk_columns: {id: $chatId}, _set: {updatedAt: now}) {
      id
    }
  }

  mutation UpdatedChat($chatId: uuid = "", $payload: communication_chats_set_input = {}, $users: communication_chat_users_set_input!) {
    updatedChat: update_communication_chats_by_pk(pk_columns: {id: $chatId}, _set: $payload) {
      id
      status
      chatUsers {
        userId
        id
      }
    }
    update_communication_chat_users_many(updates: {where: {chatId: {_eq: $chatId}}, _set: $users}) {
      affected_rows
    }
  }

  mutation InsertFirstMessage($payload: InsertFirstMessageInput!) {
    insertFirstMessage(payload: $payload) {
      chatId
      code
      message
      success
    }
  }
`;