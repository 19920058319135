import { apgGraphQL } from "./apgApi"
import { RequestError } from "./general.endpoints";
import { operationProfileMyLink } from "./graph-profile-queries";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token?: string;
  data: T;
}

const get = async ({ userId }: { userId: number }): Promise<any> => {
  const { data, errors } = await apgGraphQL(
    operationProfileMyLink,
    'GetUserMyLink',
    {
      "where": {
        "userId": { "_eq": userId }
      }
    }
  );

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.myLinks[0];
}

const update = async ({ data: object, token }: EditProfileSetProps<Partial<any>>): Promise<any> => {
  const { data, errors } = await apgGraphQL(
    operationProfileMyLink,
    'InsertOrUpdateMyLink',
    {
      "object": object
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.insertOrUpdateMyLink;
}

export const MyLinkEndpoints = {
  get,
  update
}
