import { apgGraphQL } from "./apgApi"
import { operationProfileGame } from "./graph-queries";
import { RequestError } from "./general.endpoints";
import { UserGame } from "@/domain/account/profile.interface";

export const getError = ({ title, message, response }: RequestError): RequestError => ({ title, message, response })

export interface EditProfileSetProps<T> {
  userId?: number;
  token: string;
  data: T;
}

const get = async ({ 
  userId, 
  token,
  isEditing = false,
  isPrivate = false
}: any): Promise<UserGame[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'GetUserGames',
    {
      "userId": userId,
      "isEditing": isEditing,
      "isPrivate": isPrivate
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.userGames;
}

const insert = async ({ data: userGame, token }: EditProfileSetProps<Partial<any>>): Promise<UserGame> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'InsertUserGame',
    {
      "clasificationId": userGame.clasificationId,
      "gameId": userGame.gameId,
      "playerTag": userGame.playerTag,
      "order": userGame.order
    },
    token
  )

  if (errors) {
    throw getError({
      message: errors[0].message,
      title: errors[0].message,
      response: {
        status: 404
      }
    })
  }

  return data.createUserGame;
}

const update = async ({ data: userGame, userId, token }: EditProfileSetProps<Partial<any>>): Promise<UserGame> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'UpdateUserGame',
    {
      "clasificationId": userGame.clasificationId,
      "gameId": userGame.gameId,
      "playerTag": userGame.playerTag,
      "isActive": userGame.isActive,
      "isPrivate": userGame.isPrivate
    },
    token
  )

  if (errors) {
    throw getError({
      message: errors[0].message,
      title: errors[0].extensions.code,
      response: {
        status: 404
      }
    })
  }

  return data.updateUserGame;
}

const remove = async ({ gameId, userId, token }: any): Promise<boolean> => {
  const { errors } = await apgGraphQL(
    operationProfileGame,
    'DeleteUserGame',
    {
      "gameId": gameId,
      "userId": userId
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return true;
}

const updateOrder = async (updates: Record<string, any>, token: string): Promise<UserGame[]> => {
  const { data, errors } = await apgGraphQL(
    operationProfileGame,
    'UpdateUserGamesOrder',
    {
      "updates": updates
    },
    token
  )

  if (errors) {
    throw getError(errors[0] as RequestError)
  }

  return data.updateManyGamesOrder;
}

export const GamesEndpoints = {
  get,
  insert,
  update,
  updateOrder,
  remove
}
