
import { useQuery, useMutation, useQueryClient, UseQueryResult } from "@tanstack/react-query"
import { AccountEndpoints, InsertUserToDeleted } from "@/graph/account.endpoints";
import { ApgUser, UserMyLink, UserProfileTypeWithoutId, UserSocials } from "@/domain/account.interface";
import { toast } from "react-toastify";
import { GamesEndpoints } from "@/graph/profile-games.endpoints";
import { MyLinkEndpoints } from "@/graph/profile-mylinks.endpoints";

export const useGetLoggedUser = (userId: number, initialData = {} as ApgUser): UseQueryResult<ApgUser> => {
  const userQuery = useQuery({
    queryKey: ['apg-user-signed'],
    queryFn: () => AccountEndpoints.getUserAccount(userId),
    initialData,
    staleTime: 1000 * 60 * 10,
    enabled: !!userId
  })

  return userQuery
}

export const useGetUserById = (userId: number, initialData = {} as ApgUser): UseQueryResult<ApgUser> => {
  const userQuery = useQuery({
    queryKey: ['apg-user-by-id', userId],
    queryFn: () => AccountEndpoints.getUserAccount(userId),
    initialData,
    staleTime: 1000 * 60 * 10
  })

  return userQuery
}

export const useGetProfileUser = (username: string, userProfile: ApgUser) => {
  const userQuery = useQuery(
    {
      queryKey: ['apg-user-profile', username],
      queryFn: () => AccountEndpoints.getUserAccountByUsername(username),
      staleTime: 1000 * 60 * 5,
      initialData: userProfile,
    }
  )

  return userQuery
}

export const useUpdateUserAccount = (userId: number, token: string) => {
  const queryClient = useQueryClient();
  
  return useMutation(
    {
      mutationFn: (newUserData: Partial<ApgUser>) => AccountEndpoints.updateUserAccount(userId, newUserData, token),
      onSuccess: (data) => {
        // Aquí actualizamos el contexto con la data actualizada después de la mutación
        queryClient.setQueryData(['apg-user-signed'], data);
      },
    }
  );
}

export const useInsertUserToDeleted = (token: string) => {
  return useMutation(
    {
      mutationFn: (newUserData: InsertUserToDeleted) => AccountEndpoints.insertUserToDeleted(newUserData, token),
    }
  );
}

export const useCancelUserToDeleted = (token: string) => {
  return useMutation(
    {
      mutationFn: (userId: number) => AccountEndpoints.deleteUserToDeleted(userId, token),
    }
  );
}

export const useUpdateSocial = (userId: number, token: string, t: any) => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (newSocial: Pick<UserSocials, 'socialId' | 'isActive' | 'link' | 'order'>) => {
      const { socialId, ...rest } = newSocial;
      return AccountEndpoints.updateUserSocial(userId, socialId, rest, token)
    },
    onSuccess: async () => {
      await queryClient.prefetchQuery({
        queryKey: ['apg-user-signed'], 
        queryFn: () => AccountEndpoints.getUserAccount(userId)
      });
      toast.success(`${t('account-updated-successfully')}`);
    }
  });
}

export const useUpdateManySocial = (userId: number, token: string, t: any) => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (updates: Record<string, any>) => {
      return AccountEndpoints.updateManyUserSocial(updates, token)
    },
    onSuccess: async () => {
      await queryClient.prefetchQuery({
        queryKey: ['apg-user-signed'], 
        queryFn: () => AccountEndpoints.getUserAccount(userId)
      });
      toast.success(`${t('account-updated-successfully')}`);
    },
  });
}

export const useInsertSocial = (userId: number, token: string, t: any) => {
  const queryClient = useQueryClient();
  
  return useMutation({
    mutationFn: (newSocial: Partial<UserSocials>) => AccountEndpoints.insertUserSocial(userId, newSocial, token),
    onSuccess: async () => {
      await queryClient.prefetchQuery({
        queryKey: ['apg-user-signed'], 
        queryFn: () => AccountEndpoints.getUserAccount(userId)
      });
      toast.success(`${t('account-updated-successfully')}`);
    },
  });
}

export const useDeleteSocial = (userId: number, token: string, t: any) => {
  const queryClient = useQueryClient();
  
  return useMutation(
    {
      mutationFn: (socialId: number) => AccountEndpoints.deleteUserSocial(userId, socialId, token),
      onSuccess: async (data) => {
        const { socialAccount: { name = '' } } = data.returning[0];
        await queryClient.prefetchQuery({
          queryKey: ['apg-user-signed'], 
          queryFn: () => AccountEndpoints.getUserAccount(userId)
        });
        toast.success(`${t('social-deleted-successfully').replace('{{type}}', name)}`);
      },
    }
  );
}

export const useUpdateProfileTypes = (userId: number, token: string) => {
  const queryClient = useQueryClient();
  
  return useMutation(
    {
      mutationFn: (updates: UserProfileTypeWithoutId[]) => {
        return AccountEndpoints.insertOrUpdateProfileTypes(updates, token)
      },
      onSuccess: async () => {
        await queryClient.prefetchQuery({
          queryKey: ['apg-user-signed'], 
          queryFn: () => AccountEndpoints.getUserAccount(userId)
        });
      },
    }
  );
}

export const useUpdateUserSections = (token: string, t: any) => {
  return useMutation(
    {
      mutationFn: (updates: Record<string, any>) => {
        return AccountEndpoints.updateManyUserSections(updates, token)
      },
      onSuccess: async () => toast.success(`${t('sections-reorder-success')}`)
    }
  );
}

export const useUpdateGamesOrder = (userId: number, token: string) => {
  const queryClient = useQueryClient();
  
  return useMutation(
    {
      mutationFn: (updates: Record<string, any>) => {
        return GamesEndpoints.updateOrder(updates, token)
      },
      onSuccess: async () => {
        await queryClient.prefetchQuery({
          queryKey: ['apg-user-games', userId], 
          queryFn: () => GamesEndpoints.get({ userId, token, isEditing: true })
        });
      },
    }
  );
}

export const useUpdateMyLink = ({
  token
}: {
  token: string
}) => {
  return useMutation(
    {
      mutationFn: (payload: Partial<UserMyLink>) => MyLinkEndpoints.update({ data: payload, token }),
    }
  );
}