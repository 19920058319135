
import React from 'react';
import AbacusColorIcon from '@apg.gg/icons/lib/AbacusColorIcon';
import AbacusIcon from '@apg.gg/icons/lib/AbacusIcon';
import AmazonIcon from '@apg.gg/icons/lib/AmazonIcon';
import AnalystPixelIcon from '@apg.gg/icons/lib/AnalystPixelIcon';
import ApgIcon from '@apg.gg/icons/lib/ApgIcon';
import AppleFruitColorIcon from '@apg.gg/icons/lib/AppleFruitColorIcon';
import AppleFruitIcon from '@apg.gg/icons/lib/AppleFruitIcon';
import ArrowDownIcon from '@apg.gg/icons/lib/ArrowDownIcon';
import ArrowLeftAltIcon from '@apg.gg/icons/lib/ArrowLeftAltIcon';
import ArrowLeftIcon from '@apg.gg/icons/lib/ArrowLeftIcon';
import ArrowRightAltIcon from '@apg.gg/icons/lib/ArrowRightAltIcon';
import ArrowRightIcon from '@apg.gg/icons/lib/ArrowRightIcon';
import ArrowUpIcon from '@apg.gg/icons/lib/ArrowUpIcon';
import AspectRatioIcon from '@apg.gg/icons/lib/AspectRatioIcon';
import BalanceColorIcon from '@apg.gg/icons/lib/BalanceColorIcon';
import BalanceIcon from '@apg.gg/icons/lib/BalanceIcon';
import BarChartColorIcon from '@apg.gg/icons/lib/BarChartColorIcon';
import BarChartIcon from '@apg.gg/icons/lib/BarChartIcon';
import BetaColorIcon from '@apg.gg/icons/lib/BetaColorIcon';
import BlockIcon from '@apg.gg/icons/lib/BlockIcon';
import BriefcaseColorIcon from '@apg.gg/icons/lib/BriefcaseColorIcon';
import BriefcaseIcon from '@apg.gg/icons/lib/BriefcaseIcon';
import BroadcasterPixelIcon from '@apg.gg/icons/lib/BroadcasterPixelIcon';
import CafecitoIcon from '@apg.gg/icons/lib/CafecitoIcon';
import CalendarIcon from '@apg.gg/icons/lib/CalendarIcon';
import CasterPixelIcon from '@apg.gg/icons/lib/CasterPixelIcon';
import CelebrationColorIcon from '@apg.gg/icons/lib/CelebrationColorIcon';
import CelebrationIcon from '@apg.gg/icons/lib/CelebrationIcon';
import CeoPixelIcon from '@apg.gg/icons/lib/CeoPixelIcon';
import ClipboardColorIcon from '@apg.gg/icons/lib/ClipboardColorIcon';
import ClipboardIcon from '@apg.gg/icons/lib/ClipboardIcon';
import CoachPixelIcon from '@apg.gg/icons/lib/CoachPixelIcon';
import CoinPixelIcon from '@apg.gg/icons/lib/CoinPixelIcon';
import ComedyColorIcon from '@apg.gg/icons/lib/ComedyColorIcon';
import ComedyIcon from '@apg.gg/icons/lib/ComedyIcon';
import CommentIcon from '@apg.gg/icons/lib/CommentIcon';
import CopywriterPixelIcon from '@apg.gg/icons/lib/CopywriterPixelIcon';
import CosplayerPixelIcon from '@apg.gg/icons/lib/CosplayerPixelIcon';
import CounterPixelIcon from '@apg.gg/icons/lib/CounterPixelIcon';
import CreatorPixelIcon from '@apg.gg/icons/lib/CreatorPixelIcon';
import CropLandscapeIcon from '@apg.gg/icons/lib/CropLandscapeIcon';
import CropPortraitIcon from '@apg.gg/icons/lib/CropPortraitIcon';
import CropSquareIcon from '@apg.gg/icons/lib/CropSquareIcon';
import CrosswordIcon from '@apg.gg/icons/lib/CrosswordIcon';
import CrownIcon from '@apg.gg/icons/lib/CrownIcon';
import DesignerPixelIcon from '@apg.gg/icons/lib/DesignerPixelIcon';
import DeveloperPixelIcon from '@apg.gg/icons/lib/DeveloperPixelIcon';
import DiscordIcon from '@apg.gg/icons/lib/DiscordIcon';
import DownloadIcon from '@apg.gg/icons/lib/DownloadIcon';
import DragIcon from '@apg.gg/icons/lib/DragIcon';
import DumbbellColorIcon from '@apg.gg/icons/lib/DumbbellColorIcon';
import DumbbellIcon from '@apg.gg/icons/lib/DumbbellIcon';
import EditIcon from '@apg.gg/icons/lib/EditIcon';
import EditorPixelIcon from '@apg.gg/icons/lib/EditorPixelIcon';
import EpicIcon from '@apg.gg/icons/lib/EpicIcon';
import ErrorIcon from '@apg.gg/icons/lib/ErrorIcon';
import FacebookIcon from '@apg.gg/icons/lib/FacebookIcon';
import FanPixelIcon from '@apg.gg/icons/lib/FanPixelIcon';
import FitnessPixelIcon from '@apg.gg/icons/lib/FitnessPixelIcon';
import FounderPixelIcon from '@apg.gg/icons/lib/FounderPixelIcon';
import GalleryIcon from '@apg.gg/icons/lib/GalleryIcon';
import GameControlColorIcon from '@apg.gg/icons/lib/GameControlColorIcon';
import GameControlIcon from '@apg.gg/icons/lib/GameControlIcon';
import GithubIcon from '@apg.gg/icons/lib/GithubIcon';
import GlassesColorIcon from '@apg.gg/icons/lib/GlassesColorIcon';
import GlassesIcon from '@apg.gg/icons/lib/GlassesIcon';
import GoogleIcon from '@apg.gg/icons/lib/GoogleIcon';
import GridViewIcon from '@apg.gg/icons/lib/GridViewIcon';
import GroupIcon from '@apg.gg/icons/lib/GroupIcon';
import GroupsIcon from '@apg.gg/icons/lib/GroupsIcon';
import HeartIcon from '@apg.gg/icons/lib/HeartIcon';
import HomeIcon from '@apg.gg/icons/lib/HomeIcon';
import HostPixelIcon from '@apg.gg/icons/lib/HostPixelIcon';
import HoyolabIcon from '@apg.gg/icons/lib/HoyolabIcon';
import IconApgLogo from '@apg.gg/icons/lib/IconApgLogo';
import IconApgLogoFull from '@apg.gg/icons/lib/IconApgLogoFull';
import IconBaron from '@apg.gg/icons/lib/IconBaron';
import IconDrake from '@apg.gg/icons/lib/IconDrake';
import IconDrakeCloud from '@apg.gg/icons/lib/IconDrakeCloud';
import IconDrakeInfernal from '@apg.gg/icons/lib/IconDrakeInfernal';
import IconDrakeMountain from '@apg.gg/icons/lib/IconDrakeMountain';
import IconDrakeOcean from '@apg.gg/icons/lib/IconDrakeOcean';
import IconFirstBlood from '@apg.gg/icons/lib/IconFirstBlood';
import IconHamburger from '@apg.gg/icons/lib/IconHamburger';
import IconHerald from '@apg.gg/icons/lib/IconHerald';
import IconKills from '@apg.gg/icons/lib/IconKills';
import IconLaneBottom from '@apg.gg/icons/lib/IconLaneBottom';
import IconLaneJungle from '@apg.gg/icons/lib/IconLaneJungle';
import IconLaneMid from '@apg.gg/icons/lib/IconLaneMid';
import IconLaneSupport from '@apg.gg/icons/lib/IconLaneSupport';
import IconLaneTop from '@apg.gg/icons/lib/IconLaneTop';
import IconTurret from '@apg.gg/icons/lib/IconTurret';
import IconTurretBlue from '@apg.gg/icons/lib/IconTurretBlue';
import IconTurretRed from '@apg.gg/icons/lib/IconTurretRed';
import IconWildRiftLogo from '@apg.gg/icons/lib/IconWildRiftLogo';
import IdeaColorIcon from '@apg.gg/icons/lib/IdeaColorIcon';
import IdeaIcon from '@apg.gg/icons/lib/IdeaIcon';
import IllustratorPixelIcon from '@apg.gg/icons/lib/IllustratorPixelIcon';
import ImageIcon from '@apg.gg/icons/lib/ImageIcon';
import ImagesIcon from '@apg.gg/icons/lib/ImagesIcon';
import InstagramIcon from '@apg.gg/icons/lib/InstagramIcon';
import JoytickIcon from '@apg.gg/icons/lib/JoytickIcon';
import KickIcon from '@apg.gg/icons/lib/KickIcon';
import KoFiIcon from '@apg.gg/icons/lib/KoFiIcon';
import LanguageIcon from '@apg.gg/icons/lib/LanguageIcon';
import LawyerPixelIcon from '@apg.gg/icons/lib/LawyerPixelIcon';
import LinkIcon from '@apg.gg/icons/lib/LinkIcon';
import LockIcon from '@apg.gg/icons/lib/LockIcon';
import LockOpenIcon from '@apg.gg/icons/lib/LockOpenIcon';
import LogoutIcon from '@apg.gg/icons/lib/LogoutIcon';
import LoyaltyIcon from '@apg.gg/icons/lib/LoyaltyIcon';
import MailIcon from '@apg.gg/icons/lib/MailIcon';
import ManagerPixelIcon from '@apg.gg/icons/lib/ManagerPixelIcon';
import MedicColorIcon from '@apg.gg/icons/lib/MedicColorIcon';
import MedicIcon from '@apg.gg/icons/lib/MedicIcon';
import MedicPixelIcon from '@apg.gg/icons/lib/MedicPixelIcon';
import MeditationColorIcon from '@apg.gg/icons/lib/MeditationColorIcon';
import MeditationIcon from '@apg.gg/icons/lib/MeditationIcon';
import MegaphoneColorIcon from '@apg.gg/icons/lib/MegaphoneColorIcon';
import MegaphoneIcon from '@apg.gg/icons/lib/MegaphoneIcon';
import MenuIcon from '@apg.gg/icons/lib/MenuIcon';
import MenuOpenIcon from '@apg.gg/icons/lib/MenuOpenIcon';
import MicrophoneColorIcon from '@apg.gg/icons/lib/MicrophoneColorIcon';
import MicrophoneExtColorIcon from '@apg.gg/icons/lib/MicrophoneExtColorIcon';
import MicrophoneExtIcon from '@apg.gg/icons/lib/MicrophoneExtIcon';
import MicrophoneIcon from '@apg.gg/icons/lib/MicrophoneIcon';
import MilitaryBadgeIcon from '@apg.gg/icons/lib/MilitaryBadgeIcon';
import MoonIcon from '@apg.gg/icons/lib/MoonIcon';
import MoreIcon from '@apg.gg/icons/lib/MoreIcon';
import MoreVerticalIcon from '@apg.gg/icons/lib/MoreVerticalIcon';
import MovieColorIcon from '@apg.gg/icons/lib/MovieColorIcon';
import MovieIcon from '@apg.gg/icons/lib/MovieIcon';
import NavigateNextIcon from '@apg.gg/icons/lib/NavigateNextIcon';
import NavigatePrevIcon from '@apg.gg/icons/lib/NavigatePrevIcon';
import NewsIcon from '@apg.gg/icons/lib/NewsIcon';
import NotificationIcon from '@apg.gg/icons/lib/NotificationIcon';
import NutritionistPixelIcon from '@apg.gg/icons/lib/NutritionistPixelIcon';
import ObserverPixelIcon from '@apg.gg/icons/lib/ObserverPixelIcon';
import OpenLinkIcon from '@apg.gg/icons/lib/OpenLinkIcon';
import OrganizerPixelIcon from '@apg.gg/icons/lib/OrganizerPixelIcon';
import PaletteColorIcon from '@apg.gg/icons/lib/PaletteColorIcon';
import PaletteIcon from '@apg.gg/icons/lib/PaletteIcon';
import PasswordIcon from '@apg.gg/icons/lib/PasswordIcon';
import PatreonIcon from '@apg.gg/icons/lib/PatreonIcon';
import PaypalIcon from '@apg.gg/icons/lib/PaypalIcon';
import PerformancePixelIcon from '@apg.gg/icons/lib/PerformancePixelIcon';
import PersonCelebrateColorIcon from '@apg.gg/icons/lib/PersonCelebrateColorIcon';
import PersonCelebrateIcon from '@apg.gg/icons/lib/PersonCelebrateIcon';
import PersonIcon from '@apg.gg/icons/lib/PersonIcon';
import PinFillIcon from '@apg.gg/icons/lib/PinFillIcon';
import PinOutlineIcon from '@apg.gg/icons/lib/PinOutlineIcon';
import PlayerPixelIcon from '@apg.gg/icons/lib/PlayerPixelIcon';
import PlusIcon from '@apg.gg/icons/lib/PlusIcon';
import PsychologistPixelIcon from '@apg.gg/icons/lib/PsychologistPixelIcon';
import PulseRingIcon from '@apg.gg/icons/lib/PulseRingIcon';
import RecruiterPixelIcon from '@apg.gg/icons/lib/RecruiterPixelIcon';
import RefereePixelIcon from '@apg.gg/icons/lib/RefereePixelIcon';
import ReportIcon from '@apg.gg/icons/lib/ReportIcon';
import RiotIcon from '@apg.gg/icons/lib/RiotIcon';
import ScreenColorIcon from '@apg.gg/icons/lib/ScreenColorIcon';
import ScreenIcon from '@apg.gg/icons/lib/ScreenIcon';
import SearchColorIcon from '@apg.gg/icons/lib/SearchColorIcon';
import SearchIcon from '@apg.gg/icons/lib/SearchIcon';
import SendIcon from '@apg.gg/icons/lib/SendIcon';
import SettingsIcon from '@apg.gg/icons/lib/SettingsIcon';
import ShareIcon from '@apg.gg/icons/lib/ShareIcon';
import SocialIcon from '@apg.gg/icons/lib/SocialIcon';
import SortIcon from '@apg.gg/icons/lib/SortIcon';
import SpaColorIcon from '@apg.gg/icons/lib/SpaColorIcon';
import SpaIcon from '@apg.gg/icons/lib/SpaIcon';
import StarFillIcon from '@apg.gg/icons/lib/StarFillIcon';
import StarOutlineIcon from '@apg.gg/icons/lib/StarOutlineIcon';
import StarsIcon from '@apg.gg/icons/lib/StarsIcon';
import SteamIcon from '@apg.gg/icons/lib/SteamIcon';
import StreamerPixelIcon from '@apg.gg/icons/lib/StreamerPixelIcon';
import SwordsIcon from '@apg.gg/icons/lib/SwordsIcon';
import TagIcon from '@apg.gg/icons/lib/TagIcon';
import TalentManagerPixelIcon from '@apg.gg/icons/lib/TalentManagerPixelIcon';
import TelegramIcon from '@apg.gg/icons/lib/TelegramIcon';
import ThreadsIcon from '@apg.gg/icons/lib/ThreadsIcon';
import TicketIcon from '@apg.gg/icons/lib/TicketIcon';
import TieColorIcon from '@apg.gg/icons/lib/TieColorIcon';
import TieIcon from '@apg.gg/icons/lib/TieIcon';
import TiktokIcon from '@apg.gg/icons/lib/TiktokIcon';
import TouchpadMouseIcon from '@apg.gg/icons/lib/TouchpadMouseIcon';
import TranslateIcon from '@apg.gg/icons/lib/TranslateIcon';
import TrashIcon from '@apg.gg/icons/lib/TrashIcon';
import TrendingUpIcon from '@apg.gg/icons/lib/TrendingUpIcon';
import TrophyIcon from '@apg.gg/icons/lib/TrophyIcon';
import TwitchIcon from '@apg.gg/icons/lib/TwitchIcon';
import TwitterIcon from '@apg.gg/icons/lib/TwitterIcon';
import UploadIcon from '@apg.gg/icons/lib/UploadIcon';
import VerifiedColorIcon from '@apg.gg/icons/lib/VerifiedColorIcon';
import VerifiedIcon from '@apg.gg/icons/lib/VerifiedIcon';
import VideoCamColorIcon from '@apg.gg/icons/lib/VideoCamColorIcon';
import VideoCamIcon from '@apg.gg/icons/lib/VideoCamIcon';
import ViewListIcon from '@apg.gg/icons/lib/ViewListIcon';
import VisibilityIcon from '@apg.gg/icons/lib/VisibilityIcon';
import VisibilityOffIcon from '@apg.gg/icons/lib/VisibilityOffIcon';
import WellbeingColorIcon from '@apg.gg/icons/lib/WellbeingColorIcon';
import WellbeingIcon from '@apg.gg/icons/lib/WellbeingIcon';
import XboxIcon from '@apg.gg/icons/lib/XboxIcon';
import XCircleIcon from '@apg.gg/icons/lib/XCircleIcon';
import XIcon from '@apg.gg/icons/lib/XIcon';
import YoutubeIcon from '@apg.gg/icons/lib/YoutubeIcon';
import ZoomInIcon from '@apg.gg/icons/lib/ZoomInIcon';
import ZoomOutIcon from '@apg.gg/icons/lib/ZoomOutIcon';

function getComponentByName(name: string, className: string) {
  switch (name) {
    case "abacusColor": return <AbacusColorIcon className={className} />;
case "abacus": return <AbacusIcon className={className} />;
case "amazon": return <AmazonIcon className={className} />;
case "analystPixel": return <AnalystPixelIcon className={className} />;
case "apg": return <ApgIcon className={className} />;
case "appleFruitColor": return <AppleFruitColorIcon className={className} />;
case "appleFruit": return <AppleFruitIcon className={className} />;
case "arrowDown": return <ArrowDownIcon className={className} />;
case "arrowLeftAlt": return <ArrowLeftAltIcon className={className} />;
case "arrowLeft": return <ArrowLeftIcon className={className} />;
case "arrowRightAlt": return <ArrowRightAltIcon className={className} />;
case "arrowRight": return <ArrowRightIcon className={className} />;
case "arrowUp": return <ArrowUpIcon className={className} />;
case "aspectRatio": return <AspectRatioIcon className={className} />;
case "balanceColor": return <BalanceColorIcon className={className} />;
case "balance": return <BalanceIcon className={className} />;
case "barChartColor": return <BarChartColorIcon className={className} />;
case "barChart": return <BarChartIcon className={className} />;
case "betaColor": return <BetaColorIcon className={className} />;
case "block": return <BlockIcon className={className} />;
case "briefcaseColor": return <BriefcaseColorIcon className={className} />;
case "briefcase": return <BriefcaseIcon className={className} />;
case "broadcasterPixel": return <BroadcasterPixelIcon className={className} />;
case "cafecito": return <CafecitoIcon className={className} />;
case "calendar": return <CalendarIcon className={className} />;
case "casterPixel": return <CasterPixelIcon className={className} />;
case "celebrationColor": return <CelebrationColorIcon className={className} />;
case "celebration": return <CelebrationIcon className={className} />;
case "ceoPixel": return <CeoPixelIcon className={className} />;
case "clipboardColor": return <ClipboardColorIcon className={className} />;
case "clipboard": return <ClipboardIcon className={className} />;
case "coachPixel": return <CoachPixelIcon className={className} />;
case "coinPixel": return <CoinPixelIcon className={className} />;
case "comedyColor": return <ComedyColorIcon className={className} />;
case "comedy": return <ComedyIcon className={className} />;
case "comment": return <CommentIcon className={className} />;
case "copywriterPixel": return <CopywriterPixelIcon className={className} />;
case "cosplayerPixel": return <CosplayerPixelIcon className={className} />;
case "counterPixel": return <CounterPixelIcon className={className} />;
case "creatorPixel": return <CreatorPixelIcon className={className} />;
case "cropLandscape": return <CropLandscapeIcon className={className} />;
case "cropPortrait": return <CropPortraitIcon className={className} />;
case "cropSquare": return <CropSquareIcon className={className} />;
case "crossword": return <CrosswordIcon className={className} />;
case "crown": return <CrownIcon className={className} />;
case "designerPixel": return <DesignerPixelIcon className={className} />;
case "developerPixel": return <DeveloperPixelIcon className={className} />;
case "discord": return <DiscordIcon className={className} />;
case "download": return <DownloadIcon className={className} />;
case "drag": return <DragIcon className={className} />;
case "dumbbellColor": return <DumbbellColorIcon className={className} />;
case "dumbbell": return <DumbbellIcon className={className} />;
case "edit": return <EditIcon className={className} />;
case "editorPixel": return <EditorPixelIcon className={className} />;
case "epic": return <EpicIcon className={className} />;
case "error": return <ErrorIcon className={className} />;
case "facebook": return <FacebookIcon className={className} />;
case "fanPixel": return <FanPixelIcon className={className} />;
case "fitnessPixel": return <FitnessPixelIcon className={className} />;
case "founderPixel": return <FounderPixelIcon className={className} />;
case "gallery": return <GalleryIcon className={className} />;
case "gameControlColor": return <GameControlColorIcon className={className} />;
case "gameControl": return <GameControlIcon className={className} />;
case "github": return <GithubIcon className={className} />;
case "glassesColor": return <GlassesColorIcon className={className} />;
case "glasses": return <GlassesIcon className={className} />;
case "google": return <GoogleIcon className={className} />;
case "gridView": return <GridViewIcon className={className} />;
case "group": return <GroupIcon className={className} />;
case "groups": return <GroupsIcon className={className} />;
case "heart": return <HeartIcon className={className} />;
case "home": return <HomeIcon className={className} />;
case "hostPixel": return <HostPixelIcon className={className} />;
case "hoyolab": return <HoyolabIcon className={className} />;
case "apgLogo": return <IconApgLogo className={className} />;
case "apgLogoFull": return <IconApgLogoFull className={className} />;
case "baron": return <IconBaron className={className} />;
case "drake": return <IconDrake className={className} />;
case "drakeCloud": return <IconDrakeCloud className={className} />;
case "drakeInfernal": return <IconDrakeInfernal className={className} />;
case "drakeMountain": return <IconDrakeMountain className={className} />;
case "drakeOcean": return <IconDrakeOcean className={className} />;
case "firstBlood": return <IconFirstBlood className={className} />;
case "hamburger": return <IconHamburger className={className} />;
case "herald": return <IconHerald className={className} />;
case "kills": return <IconKills className={className} />;
case "laneBottom": return <IconLaneBottom className={className} />;
case "laneJungle": return <IconLaneJungle className={className} />;
case "laneMid": return <IconLaneMid className={className} />;
case "laneSupport": return <IconLaneSupport className={className} />;
case "laneTop": return <IconLaneTop className={className} />;
case "turret": return <IconTurret className={className} />;
case "turretBlue": return <IconTurretBlue className={className} />;
case "turretRed": return <IconTurretRed className={className} />;
case "wildRiftLogo": return <IconWildRiftLogo className={className} />;
case "ideaColor": return <IdeaColorIcon className={className} />;
case "idea": return <IdeaIcon className={className} />;
case "illustratorPixel": return <IllustratorPixelIcon className={className} />;
case "image": return <ImageIcon className={className} />;
case "images": return <ImagesIcon className={className} />;
case "instagram": return <InstagramIcon className={className} />;
case "joytick": return <JoytickIcon className={className} />;
case "kick": return <KickIcon className={className} />;
case "koFi": return <KoFiIcon className={className} />;
case "language": return <LanguageIcon className={className} />;
case "lawyerPixel": return <LawyerPixelIcon className={className} />;
case "link": return <LinkIcon className={className} />;
case "lock": return <LockIcon className={className} />;
case "lockOpen": return <LockOpenIcon className={className} />;
case "logout": return <LogoutIcon className={className} />;
case "loyalty": return <LoyaltyIcon className={className} />;
case "mail": return <MailIcon className={className} />;
case "managerPixel": return <ManagerPixelIcon className={className} />;
case "medicColor": return <MedicColorIcon className={className} />;
case "medic": return <MedicIcon className={className} />;
case "medicPixel": return <MedicPixelIcon className={className} />;
case "meditationColor": return <MeditationColorIcon className={className} />;
case "meditation": return <MeditationIcon className={className} />;
case "megaphoneColor": return <MegaphoneColorIcon className={className} />;
case "megaphone": return <MegaphoneIcon className={className} />;
case "menu": return <MenuIcon className={className} />;
case "menuOpen": return <MenuOpenIcon className={className} />;
case "microphoneColor": return <MicrophoneColorIcon className={className} />;
case "microphoneExtColor": return <MicrophoneExtColorIcon className={className} />;
case "microphoneExt": return <MicrophoneExtIcon className={className} />;
case "microphone": return <MicrophoneIcon className={className} />;
case "militaryBadge": return <MilitaryBadgeIcon className={className} />;
case "moon": return <MoonIcon className={className} />;
case "more": return <MoreIcon className={className} />;
case "moreVertical": return <MoreVerticalIcon className={className} />;
case "movieColor": return <MovieColorIcon className={className} />;
case "movie": return <MovieIcon className={className} />;
case "navigateNext": return <NavigateNextIcon className={className} />;
case "navigatePrev": return <NavigatePrevIcon className={className} />;
case "news": return <NewsIcon className={className} />;
case "notification": return <NotificationIcon className={className} />;
case "nutritionistPixel": return <NutritionistPixelIcon className={className} />;
case "observerPixel": return <ObserverPixelIcon className={className} />;
case "openLink": return <OpenLinkIcon className={className} />;
case "organizerPixel": return <OrganizerPixelIcon className={className} />;
case "paletteColor": return <PaletteColorIcon className={className} />;
case "palette": return <PaletteIcon className={className} />;
case "password": return <PasswordIcon className={className} />;
case "patreon": return <PatreonIcon className={className} />;
case "paypal": return <PaypalIcon className={className} />;
case "performancePixel": return <PerformancePixelIcon className={className} />;
case "personCelebrateColor": return <PersonCelebrateColorIcon className={className} />;
case "personCelebrate": return <PersonCelebrateIcon className={className} />;
case "person": return <PersonIcon className={className} />;
case "pinFill": return <PinFillIcon className={className} />;
case "pinOutline": return <PinOutlineIcon className={className} />;
case "playerPixel": return <PlayerPixelIcon className={className} />;
case "plus": return <PlusIcon className={className} />;
case "psychologistPixel": return <PsychologistPixelIcon className={className} />;
case "pulseRing": return <PulseRingIcon className={className} />;
case "recruiterPixel": return <RecruiterPixelIcon className={className} />;
case "refereePixel": return <RefereePixelIcon className={className} />;
case "report": return <ReportIcon className={className} />;
case "riot": return <RiotIcon className={className} />;
case "screenColor": return <ScreenColorIcon className={className} />;
case "screen": return <ScreenIcon className={className} />;
case "searchColor": return <SearchColorIcon className={className} />;
case "search": return <SearchIcon className={className} />;
case "send": return <SendIcon className={className} />;
case "settings": return <SettingsIcon className={className} />;
case "share": return <ShareIcon className={className} />;
case "social": return <SocialIcon className={className} />;
case "sort": return <SortIcon className={className} />;
case "spaColor": return <SpaColorIcon className={className} />;
case "spa": return <SpaIcon className={className} />;
case "starFill": return <StarFillIcon className={className} />;
case "starOutline": return <StarOutlineIcon className={className} />;
case "stars": return <StarsIcon className={className} />;
case "steam": return <SteamIcon className={className} />;
case "streamerPixel": return <StreamerPixelIcon className={className} />;
case "swords": return <SwordsIcon className={className} />;
case "tag": return <TagIcon className={className} />;
case "talentManagerPixel": return <TalentManagerPixelIcon className={className} />;
case "telegram": return <TelegramIcon className={className} />;
case "threads": return <ThreadsIcon className={className} />;
case "ticket": return <TicketIcon className={className} />;
case "tieColor": return <TieColorIcon className={className} />;
case "tie": return <TieIcon className={className} />;
case "tiktok": return <TiktokIcon className={className} />;
case "touchpadMouse": return <TouchpadMouseIcon className={className} />;
case "translate": return <TranslateIcon className={className} />;
case "trash": return <TrashIcon className={className} />;
case "trendingUp": return <TrendingUpIcon className={className} />;
case "trophy": return <TrophyIcon className={className} />;
case "twitch": return <TwitchIcon className={className} />;
case "twitter": return <TwitterIcon className={className} />;
case "upload": return <UploadIcon className={className} />;
case "verifiedColor": return <VerifiedColorIcon className={className} />;
case "verified": return <VerifiedIcon className={className} />;
case "videoCamColor": return <VideoCamColorIcon className={className} />;
case "videoCam": return <VideoCamIcon className={className} />;
case "viewList": return <ViewListIcon className={className} />;
case "visibility": return <VisibilityIcon className={className} />;
case "visibilityOff": return <VisibilityOffIcon className={className} />;
case "wellbeingColor": return <WellbeingColorIcon className={className} />;
case "wellbeing": return <WellbeingIcon className={className} />;
case "xbox": return <XboxIcon className={className} />;
case "xCircle": return <XCircleIcon className={className} />;
case "x": return <XIcon className={className} />;
case "youtube": return <YoutubeIcon className={className} />;
case "zoomIn": return <ZoomInIcon className={className} />;
case "zoomOut": return <ZoomOutIcon className={className} />;
    default: return null;
  }
}

export default getComponentByName;
